import { Component } from '@angular/core';
import { AutologoutService } from './_services/autologout.service';
// import it to your component
// import it to your component

@Component({
  selector: 'app-root',
  template: `<router-outlet></router-outlet>`,
})
export class AppComponent {
  title = 'partners';

  constructor(private autoLogoutService: AutologoutService) {}
}
