import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/_services/auth.service';
import { NewsletterService } from 'src/app/_services/newsletter.service';
import { environment } from 'src/environments/environment';
import { ProfileService } from '../../../_services/profile.service';
import { Globals } from '../../../globals';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-dashboard-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent {
  DATE_TIME_FORMAT = environment.DATE_TIME_FORMAT;
  @Output() userDataEvent = new EventEmitter();
  @Output() roleDateEvent = new EventEmitter<string>();
  Admindetail: any;
  notificationList: any = [];
  totalNotification = 0;
  profileSubscribeData: any;
  user: any;
  totalUnreadNotification: any = 0;
  constructor(
    private profileservice: ProfileService,
    private AuthService: AuthService,
    private router: Router,
    private globals: Globals,
    private NewsletterService: NewsletterService,
    private toastrService: ToastrService
  ) {}
  ngOnDestroy(): void {
    if (this.profileSubscribeData) {
      this.profileSubscribeData.unsubscribe();
    }
  }
  ngOnInit(): void {
    this._getProfile();
  }
  public _logout(): void {
    let obj = {
      token: this.user?.token,
      hardSignOut: true,
    };
    if (this.user)
      this.profileSubscribeData = this.AuthService._logout(obj).subscribe({
        next: (result: any) => {
          if (result.status == 'SUCCESS') {
            localStorage.clear();
            this.user = null;
            this.router.navigate(['/login']);
          }
        },
        error: (err: any) => {
          let errorm = err.error.error.clientErrorMessage;
          this.toastrService.error(errorm, '');
          return;
        },
        complete: () => {},
      });
    else {
      localStorage.clear();
      this.user = null;
      this.router.navigate(['/login']);
    }
  }
  profileData: any = '';
  _getProfile() {
    this.profileSubscribeData = this.profileservice._getProfile().subscribe({
      next: (result: any) => {
        if (result.status == 'SUCCESS') {
          this.profileData = result.data;
          this.userDataEvent.emit(this.profileData);
          this.user = this.globals.getUser();
          if (this.user) {
            this._getNotificationList();
          }
          setInterval(() => {
            this.user = this.globals.getUser();
            if (this.user) {
              this._getNotificationList();
            }
          }, 60000);
        }
      },
      error: (err: any) => {},
      complete: () => {},
    });
  }
  und = 0;
  cnt = 0;
  _getNotificationList() {
    this.NewsletterService._getNotificationList().subscribe((x) => {
      this.notificationList = x.data.notificationList;
      this.totalNotification = x.data.notificationList.filter(
        (notication) => notication.status === 'SENT'
      ).length;
      let unredcnt: number = parseInt(
        localStorage.getItem('unreadnCnt') ?? '0'
      );

      if (this.totalNotification > unredcnt) {
        this.cnt = this.totalNotification - unredcnt;
        this.totalUnreadNotification = this.cnt;
        this.und = unredcnt + this.cnt;
      } else if (this.totalNotification == unredcnt) {
        this.totalUnreadNotification = 0;
      } else {
        this.totalUnreadNotification = this.totalNotification;
      }
    });
  }
  readNotifications() {
    this.NewsletterService._readNotifications().subscribe({
      next: (result: any) => {
        if (result.status == 'SUCCESS') {
          this.totalUnreadNotification = 0;
        }
      },
      error: (err: any) => {},
      complete: () => {},
    });
  }
}
